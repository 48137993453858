/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import VodafoneImg from '../../../../../assets/images/vil-red-logo.png'
// import './common.scss';
// import './caf-variable.scss';
import TermsConditionNew from '../termsCondition'
import { useLoadRomsFormFieldListData } from '../../../../../hooks/form.hooks'
import { Center, Spinner, useTheme } from '@chakra-ui/react'
import { loadImageBlob } from '../../../../../utils/aws.util'
import { encode } from '../cafCrf.util'
import { useAuthContext } from '../../../../../context/auth.context'

const GIPTCafForm = ({ formData }) => {
	const [cafData, setCafData] = useState(false)
	const [cafDataupdated, setUpdatedCafData] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)

	const { mutate } = useLoadRomsFormFieldListData()
	let componentRef = React.useRef()
	const chakraTheme = useTheme()

	const {
		state: { authData },
	} = useAuthContext()

	const CustomComponent = () => {
		return (
			<div className='section2'>
				<div className='font8'>
					<p className='ligthbg'>TERMS & CONDITIONS</p>
				</div>
			</div>
		)
	}

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCafData(false)
					setUpdatedCafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	useEffect(() => {
		setCafData(true)
		fetchFieldListData()
	}, [])

	useEffect(() => {
		let data = cafDataupdated
		if (!!data) {
			if (!!data[314308].value) {
				// customer company seal
				let custCompanySeal = data[314308].value
				loadImageBlob(custCompanySeal, (data, type) => {
					setImageData(data)
					setImageType(type)
				})
			}
			if (!!data[314305].value) {
				// manager sign url
				let acctManagerSignUrl = data[314305].value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
				})
			}
		}
	}, [cafDataupdated])

	let cafContentView = ''
	if (!!cafDataupdated) {
		let customerSignSplit =
			!!cafDataupdated[314307].value && cafDataupdated[314307].value.length > 0
				? cafDataupdated[314307].value.split(' ')
				: ''
		let firstCaps
		let secondBold
		let Signcss
		if (customerSignSplit.length > 1) {
			firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
			secondBold = customerSignSplit['1']
			Signcss = 'signate_img input_sign'
		} else {
			firstCaps = customerSignSplit
			secondBold = ''
			Signcss = 'signate_img input_sign under_line'
		}

		cafContentView = (
			<div>
				<page size='A4'>
					<div className='section2'>
						<div className='font8'>
							<p className='over_hid'>
								<img src={VodafoneImg} />
								{/* <img src={IdeaImg} className="pullr" /> */}
							</p>
							<p className='customfom redcolor text-cent'>
								<strong>
									Customer Application Form –Variable IPLC
									<br />
									(Global IP Trunk)
								</strong>
							</p>
							<div className='over_hid'>
								<div className='pd_t5 pad_r25'>
									<div className='caf'>
										CAF ID
										<input
											className='form_col'
											type='text'
											name
											value={cafDataupdated[314189].value}
											readOnly
										/>
									</div>
								</div>
							</div>
							<div className='over_hid'>
								<div className='pd_t5 pad_r25'>
									<div className='caf'>
										Account Code
										<input
											className='form_col'
											type='text'
											name
											value={cafDataupdated[314190].value}
											readOnly
										/>
									</div>
								</div>
							</div>
							<div className='pad_5t width80'>
								<strong>Company(Customer) Name</strong>

								<span className='pad_l10'>
									<input
										className='form_col_input flright width75'
										type='text'
										name
										value={cafDataupdated[314188].value}
									/>
								</span>
							</div>
							<div className='pad_5t width80 '>
								<input
									className='form_col_input flright width75'
									type='text'
									name
									value={''}
								/>
							</div>
							<p className='pad_t30'>
								<strong>Headquarter Address:</strong>
							</p>
							<div className='pad_l100'>
								<p className='auth'>
									Contact Person Name
									<input
										className='form_col width60'
										type='text'
										name
										value={cafDataupdated[314192].value}
										readOnly
									/>
								</p>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Designation
										<input
											className='form_col width65'
											type='text'
											name
											value={cafDataupdated[314193].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Department
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314194].value}
											readOnly
										/>
									</div>
								</div>
								<p className='auth'>
									Address
									<input
										className='form_col width70'
										type='text'
										name
										value={cafDataupdated[314195].value}
										readOnly
									/>
								</p>
								<p className='auth'>
									<input
										className='form_col width70 mar_l25'
										type='text'
										name
										value={''}
									/>
								</p>
								<p className='auth'>
									Landmark
									<input
										className='form_col width70'
										type='text'
										name
										value={cafDataupdated[314196].value}
										readOnly
									/>
								</p>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Country
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314197].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Pin Code
										<input
											className='form_col width55'
											type='text'
											name
											value={cafDataupdated[314198].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Telephone Number:
										<input
											className='form_col width58'
											type='text'
											name
											value={cafDataupdated[314199].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Fax:
										<input
											className='form_col width60'
											type='text'
											name
											value={cafDataupdated[314200].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Contact email id:
										<input
											className='form_col width58'
											type='text'
											name
											value={cafDataupdated[314201].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Mobile No./ Alt No.:
										<input
											className='form_col width40'
											type='text'
											name
											value={cafDataupdated[314202].value}
											readOnly
										/>
									</div>
								</div>
							</div>
							<p className='pad_t30'>
								<strong>Billing Address:</strong>
							</p>
							<div className='pad_l100'>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Designation
										<input
											className='form_col width65'
											type='text'
											name
											value={cafDataupdated[314204].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Department
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314205].value}
											readOnly
										/>
									</div>
								</div>
								<p className='auth'>
									Address
									<input
										className='form_col width70'
										type='text'
										name
										value={cafDataupdated[314206].value}
										readOnly
									/>
								</p>
								<p className='auth'>
									<input
										className='form_col width70 mar_l25'
										type='text'
										name
										value={''}
									/>
								</p>
								<p className='auth'>
									Landmark
									<input
										className='form_col width70'
										type='text'
										name
										value={cafDataupdated[314207].value}
										readOnly
									/>
								</p>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Country
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314208].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Pin Code
										<input
											className='form_col width55'
											type='text'
											name
											value={cafDataupdated[314209].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Telephone Number:
										<input
											className='form_col width58'
											type='text'
											name
											value={cafDataupdated[314210].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Fax:
										<input
											className='form_col width60'
											type='text'
											name
											value={cafDataupdated[314211].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Contact email id:
										<input
											className='form_col width58'
											type='text'
											name
											value={cafDataupdated[314212].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Mobile No./ Alt No.:
										<input
											className='form_col width40'
											type='text'
											name
											value={cafDataupdated[314213].value}
											readOnly
										/>
									</div>
								</div>
							</div>
							<p className='pad_t30'>
								<strong>Installation Address:</strong>
							</p>
							<div className='pad_l100'>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Designation
										<input
											className='form_col width65'
											type='text'
											name
											value={cafDataupdated[314215].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Department
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314216].value}
											readOnly
										/>
									</div>
								</div>
								<p className='auth'>
									Address
									<input
										className='form_col width70'
										type='text'
										name
										value={cafDataupdated[314217].value}
										readOnly
									/>
								</p>
								<p className='auth'>
									<input
										className='form_col width70 mar_l25'
										type='text'
										name
										value={''}
									/>
								</p>
								<p className='auth'>
									Installation Address:
									<input
										className='form_col width70'
										type='text'
										name
										value={cafDataupdated[314217].value}
										readOnly
									/>
								</p>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Country
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314219].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Pin Code
										<input
											className='form_col width55'
											type='text'
											name
											value={cafDataupdated[314220].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Telephone Number:
										<input
											className='form_col width58'
											type='text'
											name
											value={cafDataupdated[314221].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Fax:
										<input
											className='form_col width60'
											type='text'
											name
											value={cafDataupdated[314222].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Contact email id:
										<input
											className='form_col width58'
											type='text'
											name
											value={cafDataupdated[314223].value}
											readOnly
										/>
									</div>
									<div className='fleft width55'>
										Mobile No./ Alt No.:
										<input
											className='form_col width40'
											type='text'
											name
											value={cafDataupdated[314224].value}
											readOnly
										/>
									</div>
								</div>
							</div>
							<div style={{ width: '90rem' }}>
								<div className='signate float_img' style={{ bottom: '20px' }}>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}

									{imageData != null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2'>
						<div className='font8'>
							<p className='pad_t30'>
								<strong>GST Customer Information:</strong>
							</p>
							<div className='pad_l50'>
								<div className='check margin0 over_hid'>
									<div className='fleft width10'>
										GSTIN
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314226].value.trim() === 'GSTIN'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width10'>
										UIN
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314226].value.trim() === 'UIN'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width20'>
										GST_ISD
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314226].value.trim() === 'GST_ISD'
													? true
													: false
											}
											readOnly
										/>
									</div>
								</div>
								<div className='check margin0 over_hid typecheck'>
									<div className='fleft width12 pad_t2'>Customer Type</div>
									<div className='fleft width12'>
										Normal
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() === 'Normal'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										SEZ
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() === 'SEZ'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Diplomat
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() === 'Diplomat'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Embassy
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() === 'Embassy'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Consulate
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() === 'Consulate'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Special Agency
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() === 'Special Agency'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Persons/ Class of Persons
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314227].value.trim() ===
												'Persons/Class of Persons'
													? true
													: false
											}
											readOnly
										/>
									</div>
								</div>
								<p className='auth'>
									GSTIN/ UIN/ GST_ID No
									<input
										className='form_col wid30'
										type='text'
										name
										value={cafDataupdated[314228].value}
										readOnly
									/>
								</p>
								<p className='auth'>
									GST Regd. Address (in Installation State)
									<input
										className='form_col wid50'
										type='text'
										name
										value={cafDataupdated[314510].value}
										readOnly
									/>
								</p>
								<p className='auth'>
									<input
										className='form_col wid50 mar_l182'
										type='text'
										name
										value={''}
										readOnly
									/>
								</p>
								<div className='check chekinner over_hid typecheck gstcheck gstone'>
									<div className='fleft width12 pad_t2'>GST Customer Dept</div>
									<div className='fleft width12'>
										IT
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'IT'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Tech
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'Tech'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Customer Service
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() ===
												'Customer Service'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Legal
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'Legal'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Finance
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'Finance'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Purchase
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'Purchase'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										SCM
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'SCM'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'Delivery'
													? true
													: false
											}
											readOnly
										/>
										Delivery
									</div>
									<div className='fleft width20 pad_t2'>
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314230].value.trim() === 'Others'
													? true
													: false
											}
											readOnly
										/>
										Others
										<input
											className='form_col oth_gst'
											type='text'
											name
											value={cafDataupdated[314231].value}
											readOnly
										/>
									</div>
								</div>
								<p className='auth'>
									GST Supporting Document (GST Certificate, SEZ Certificate,
									Diplomat certificate)
									<input
										className='form_col width40'
										type='text'
										name
										value={cafDataupdated[314232].value}
										readOnly
									/>
								</p>
							</div>
							<p className='pad_t30'>
								<strong>Customer Information:</strong>
							</p>
							<div className='check margin0 over_hid typecheck'>
								<div className='fleft width12 pad_t2'>Customer Type</div>
								<div className='fleft width12'>
									Normal
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314234].value.trim() === 'Normal'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									General
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314234].value.trim() === 'General'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									ISP
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314234].value.trim() === 'ISP'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									OSP
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314234].value.trim() === 'OSP'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									BPO
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314234].value.trim() === 'BPO'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Others
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314234].value.trim() === 'Others'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<div className='check margin0 over_hid typecheck'>
								<div className='fleft width12 pad_t2'>
									Customer Vertical Type{' '}
								</div>
								<div className='fleft width12'>
									Automobile
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Automobile'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Consumer Goods
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Consumer Goods'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Diversified &amp; Projects
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() ===
											'Diversified & Projects'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Education
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Education'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Engineering
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Engineering'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Finance
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Finance'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<div className='check margin0 over_hid typecheck'>
								<div className='fleft width12 pad_t2 pad_l80'> &nbsp; </div>
								<div className='fleft width12'>
									Government
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Government'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Healthcare
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Healthcare'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Industry Associating
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() ===
											'Industry Associating'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									IT &amp; Telecom
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'IT & Telecom'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Logistics
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Logistics'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Manufacturing &amp; Processing
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() ===
											'Manufacturing & Processing'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<div className='check margin0 over_hid typecheck'>
								<div className='fleft width12 pad_t2 pad_l80'> &nbsp; </div>
								<div className='fleft width12'>
									Trading
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Trading'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Services
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Services'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Projects EPC
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Projects EPC'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									Others
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314235].value.trim() === 'Others'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<p className='auth'>
								International OSP No
								<input
									className='form_col wid30'
									type='text'
									name
									value={cafDataupdated[314236].value}
									readOnly
								/>
							</p>
							<p className='pad_t20'>
								<strong>Service Requirements:</strong>
							</p>
							<div className='pad_l50'>
								<div className='check margin0 over_hid typecheck'>
									<div className='fleft pad_t2 pad_r50 pad_r25'>Flavour</div>
									<div className='fleft width15'>
										Managed
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314238].value.trim() === 'Managed'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Unmanaged
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314238].value.trim() === 'Unmanaged'
													? true
													: false
											}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r50'>
										Concurrent number of Sessions:
									</div>
									<div className='fleft width50'>
										Voice
										<input
											className='form_col width10'
											type='text'
											name
											value={cafDataupdated[314240].value}
											readOnly
										/>
										<span className='fnt7 pad_l10'>
											(Min Session count= 20)
										</span>
									</div>
								</div>
								<div className='check margin0 over_hid pad_5t'>
									<div className='fleft pad_r25'>Codec </div>
									<div className='fleft width10'>
										G.711
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314241].value.trim() === 'G.711'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft '>
										G.729
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314241].value.trim() === 'G.729'
													? true
													: false
											}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_t10'>
									<div className='fleft pad_r50'>
										Additional bandwidth needed:{' '}
									</div>
									<div className='fleft width5 pad_l50'>
										<input
											className='form_col width90'
											type='text'
											name
											value={cafDataupdated[314452].value}
											readOnly
										/>
									</div>
									<div className='fleft check'>
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314242].value.trim() === 'Kbps'
													? true
													: false
											}
											readOnly
										/>
										Kbps
									</div>
									<div className='fleft check pad_l15'>
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314242].value.trim() === 'Mbps'
													? true
													: false
											}
											readOnly
										/>
										Mbps
									</div>
									<div className='fleft check pad_l15'>
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314242].value.trim() === 'Gbps'
													? true
													: false
											}
											readOnly
										/>
										Gbps
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r50'>DID/ ITFS Required: </div>
									<div className='fleft check pad_l50'>
										Yes
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314243].value.trim() === 'Yes'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft check pad_l10'>
										No
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314243].value.trim() === 'No'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft pad_l30 width50 pad_5t pad_l50'>
										Quantity
										<input
											className='form_col width10'
											type='text'
											name
											value={cafDataupdated[314244].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r50'>
										DID Number Block Quantity:{' '}
									</div>
									<div className='fleft width10 pad_l50'>
										<input
											className='form_col width90'
											type='text'
											name
											value={cafDataupdated[314245].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r50'>DID Numbers Required: </div>
									<div className='fleft width70 pad_l50'>
										<input
											className='form_col width90'
											type='text'
											name
											value={cafDataupdated[314246].value}
											readOnly
										/>
									</div>
								</div>
								<div className='fon8 text_r pad_r75'>
									(Separate numbers using commas)
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r50'>
										TFS Number Block Quantity:{' '}
									</div>
									<div className='fleft width10 pad_l50'>
										<input
											className='form_col width90'
											type='text'
											name
											value={cafDataupdated[314247].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r50'>TFS Numbers Required: </div>
									<div className='fleft width70 pad_l50'>
										<input
											className='form_col width90'
											type='text'
											name
											value={cafDataupdated[314248].value}
											readOnly
										/>
									</div>
								</div>
								<div className='fon8 text_r pad_r75'>
									(Separate numbers using commas)
								</div>
								<div className='check margin0 over_hid typecheck'>
									<div className='fleft width12 pad_t2'>
										Facilities required:
									</div>
									<div className='fleft width12'>
										Inbound
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314249].value.trim() === 'Inbound'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Outbound
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314249].value.trim() === 'Outbound'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										Both
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314249].value.trim() === 'Both'
													? true
													: false
											}
											readOnly
										/>
									</div>
								</div>
								<div className='check margin0 over_hid typecheck'>
									<div className='fleft width12 pad_t2'>
										Existing VIL L3 MPLS customer:
									</div>
									<div className='fleft width12'>
										Yes
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314251].value.trim() === 'Yes'
													? true
													: false
											}
											readOnly
										/>
									</div>
									<div className='fleft width12'>
										No
										<input
											className='form_col'
											type='checkbox'
											name
											checked={
												cafDataupdated[314251].value.trim() === 'No'
													? true
													: false
											}
											readOnly
										/>
									</div>
								</div>
								<p>
									Existing MPLS: Managed/Unmanaged? Flavor to be decided basis
									MPLS
								</p>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft pad_r20'>
										Circuit ID (in case existing MPLS customer):
									</div>
									<div className='fleft width40 pad_l50'>
										<input
											className='form_col width50'
											type='text'
											name
											value={cafDataupdated[314250].value}
											readOnly
										/>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width12 pad_t2'>
											CPE Provided by:{' '}
										</div>
										<div className='fleft width12'>
											VIL
											<input
												className='form_col'
												type='checkbox'
												name
												checked={
													cafDataupdated[314253].value.trim() === 'VIL'
														? true
														: false
												}
												readOnly
											/>
										</div>
										<div className='fleft width12'>
											Customer
											<input
												className='form_col'
												type='checkbox'
												name
												checked={
													cafDataupdated[314253].value.trim() === 'Customer'
														? true
														: false
												}
												readOnly
											/>
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width12 pad_t2'>SBC Provided by:</div>
										<div className='fleft width12'>
											VIL
											<input
												className='form_col'
												type='checkbox'
												name
												checked={
													cafDataupdated[314254].value.trim() === 'VIL'
														? true
														: false
												}
												readOnly
											/>
										</div>
										<div className='fleft width12'>
											Customer
											<input
												className='form_col'
												type='checkbox'
												name
												checked={
													cafDataupdated[314254].value.trim() === 'Customer'
														? true
														: false
												}
												readOnly
											/>
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width12 pad_t2'>
											If provided by VIL:{' '}
										</div>
										<div className='fleft width12'>
											Rent
											<input
												className='form_col'
												type='checkbox'
												name
												checked={
													cafDataupdated[314255].value.trim() === 'Rent'
														? true
														: false
												}
												readOnly
											/>
										</div>
										<div className='fleft width12'>
											Sale
											<input
												className='form_col'
												type='checkbox'
												name
												checked={
													cafDataupdated[314255].value.trim() === 'Sale'
														? true
														: false
												}
												readOnly
											/>
										</div>
										<div className='wifitable pad_t30'>
											<table
												width='70%'
												border={0}
												cellSpacing={0}
												cellPadding={0}
											>
												<tbody>
													<tr>
														<td>
															<strong>Sr. No</strong>
														</td>
														<td>
															<strong>Type (Standard/Other)</strong>
														</td>
														<td>
															<strong>Make</strong>
														</td>
														<td>
															<strong>Model</strong>
														</td>
														<td>
															<strong>Version</strong>
														</td>
														<td>
															<strong>Quantity</strong>
														</td>
													</tr>
													<tr>
														<td>1</td>
														<td>{cafDataupdated[314256].value}</td>
														<td>{cafDataupdated[314257].value}</td>
														<td>{cafDataupdated[314258].value}</td>
														<td>{cafDataupdated[314259].value}</td>
														<td>{cafDataupdated[314260].value}</td>
													</tr>
													<tr>
														<td>2</td>
														<td>{cafDataupdated[314261].value}</td>
														<td>{cafDataupdated[314262].value}</td>
														<td>{cafDataupdated[314263].value}</td>
														<td>{cafDataupdated[314264].value}</td>
														<td>{cafDataupdated[314265].value}</td>
													</tr>
													<tr>
														<td>3</td>
														<td>{cafDataupdated[314266].value}</td>
														<td>{cafDataupdated[314267].value}</td>
														<td>{cafDataupdated[314268].value}</td>
														<td>{cafDataupdated[314269].value}</td>
														<td>{cafDataupdated[314270].value}</td>
													</tr>
													<tr>
														<td>4</td>
														<td>{cafDataupdated[314495].value}</td>
														<td>{cafDataupdated[314496].value}</td>
														<td>{cafDataupdated[314497].value}</td>
														<td>{cafDataupdated[314498].value}</td>
														<td>{cafDataupdated[314499].value}</td>
													</tr>
													<tr>
														<td>5</td>
														<td>{cafDataupdated[314500].value}</td>
														<td>{cafDataupdated[314501].value}</td>
														<td>{cafDataupdated[314502].value}</td>
														<td>{cafDataupdated[314503].value}</td>
														<td>{cafDataupdated[314504].value}</td>
													</tr>
													<tr>
														<td>6</td>
														<td>{cafDataupdated[314505].value}</td>
														<td>{cafDataupdated[314506].value}</td>
														<td>{cafDataupdated[314507].value}</td>
														<td>{cafDataupdated[314508].value}</td>
														<td>{cafDataupdated[314509].value}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div style={{ width: '90rem' }}>
										<div
											className='signate float_img'
											style={{ bottom: '20px' }}
										>
											{firstCaps !== '' ? (
												<div style={{ padding: '10px' }} className={Signcss}>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}

											{imageData != null && imageData !== '' ? (
												<div
													style={{ padding: '10px' }}
													className='signate_img'
												>
													<img
														src={
															imageData != null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(imageData)
																: ''
														}
														alt={'Company Seal'}
													/>
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2'>
						<div className='font8'>
							<p className>
								<strong>Last Mile Detail</strong>
							</p>
							<div className='check margin0 over_hid typecheck'>
								<div className='fleft width12 pad_t2'>
									Dual Last Mile Needed
								</div>
								<div className='fleft width12'>
									Yes
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314272].value.trim() === 'Yes'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									No
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314272].value.trim() === 'No'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft width40'>
									Primary LM Service Provider:
									<input
										className='form_col width35 flright'
										type='text'
										name
										value={cafDataupdated[314273].value}
									/>
								</div>
								<div className='fleft width30 pad_l50'>
									Primary Bandwidth:
									<input
										className='form_col width35 flright'
										type='text'
										name
										value={cafDataupdated[314274].value}
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft width40'>
									Secondary LM Service Provided:
									<input
										className='form_col width35 flright'
										type='text'
										name
										value={cafDataupdated[314275].value}
									/>
								</div>
								<div className='fleft width30 pad_l50'>
									Secondary bandwidth:
									<input
										className='form_col width35 flright'
										type='text'
										name
										value={cafDataupdated[314276].value}
									/>
								</div>
							</div>
							<div className='check margin0 over_hid typecheck pad_5t'>
								<div className='fleft width12 pad_t2'>Primary LM Media: </div>
								<div className='fleft width12'>
									Fibe
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314277].value.trim() === 'Fiber'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									UBR
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314277].value.trim() === 'UBR'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<div className='check margin0 over_hid typecheck pad_5t'>
								<div className='fleft width12 pad_t2'>Secondary LM Media:</div>
								<div className='fleft width12'>
									Fibe
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314278].value.trim() === 'Fiber'
												? true
												: false
										}
										readOnly
									/>
								</div>
								<div className='fleft width12'>
									UBR
									<input
										className='form_col'
										type='checkbox'
										name
										checked={
											cafDataupdated[314278].value.trim() === 'UBR'
												? true
												: false
										}
										readOnly
									/>
								</div>
							</div>
							<p>
								<strong>Other Operator Contact Details:</strong>
							</p>
							<div className='wifitable'>
								<table width='70%' border={0} cellSpacing={0} cellPadding={0}>
									<tbody>
										<tr>
											<td>
												<strong>Department</strong>
											</td>
											<td>
												<strong>First Name</strong>
											</td>
											<td>
												<strong>Last Name</strong>
											</td>
											<td>
												<strong>Contact Numbe</strong>
											</td>
											<td>
												<strong>Email</strong>
											</td>
										</tr>
										<tr>
											<td>&nbsp; {cafDataupdated[314280].value}</td>
											<td>&nbsp; {cafDataupdated[314281].value}</td>
											<td>&nbsp; {cafDataupdated[314282].value}</td>
											<td>&nbsp; {cafDataupdated[314283].value}</td>
											<td>&nbsp; {cafDataupdated[314284].value}</td>
										</tr>
										<tr>
											<td>&nbsp; {cafDataupdated[314285].value}</td>
											<td>&nbsp; {cafDataupdated[314286].value}</td>
											<td>&nbsp; {cafDataupdated[314287].value}</td>
											<td>&nbsp; {cafDataupdated[314288].value}</td>
											<td>&nbsp; {cafDataupdated[314289].value}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p className='pad_t20'>
								<strong>Commercial</strong>
							</p>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>Tariff Plan Name: </div>
								<div className='fleft width60 pad_l50'>
									<input
										className='form_col width50'
										type='text'
										name
										value={cafDataupdated[314290].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Session Rental (monthly):
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314291].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>Security Deposit </div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314292].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Number Porting Charges (one time):
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314293].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Service Registration Charges (one time):
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314294].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									CPE charges (one time) if any:
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314295].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									CPE rental (monthly) if any:
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314296].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Bandwidth rental (monthly) if any:
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314297].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Misc. Charges (monthly/ one time):
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314298].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									DID rental (monthly) if any:
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314299].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									ITFS rental (monthly) if any:
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314300].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Total Amount Payable:{' '}
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314301].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Total Amount Paid with CAF:
								</div>
								<div className='fleft width60 pad_l50'>
									Rs.
									<input
										className='form_col width20'
										type='text'
										name
										value={cafDataupdated[314302].value}
										readOnly
									/>
								</div>
							</div>
							<div className='dectext'>
								<span>
									<strong>Declaration:</strong>
									<br />
									We have read and understood the General Terms and Conditions
									mentioned overleaf and unconditionally accept them as binding
									on us. We have understood all the rates, tariffs and other
									related conditions at which the Services will be provider
									inside India as applicable on this date as amended from time
									to time. We undertake to pay all the charges raised on account
									of the Services availed. We further declare and undertake that
									the above provided by us is true and correct in all aspect.
								</span>
							</div>
							<div className='auth margin0 over_hid pad_20t'>
								<div className='fleft pad_r50 width25'>
									Customer Account Code:{' '}
								</div>
								<div className='fleft width60'>
									<input
										className='form_col width60'
										type='text'
										name
										value={cafDataupdated[314303].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Name of the Account Manager:
								</div>
								<div className='fleft width60'>
									<input
										className='form_col width60'
										type='text'
										name
										value={cafDataupdated[314304].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Signature of the Account Manager
								</div>

								{/* <div className="fleft width60">
                  <input
                    className="form_col width60"
                    type="text"
                    name
                    value={cafDataupdated[314305].value}
                    readOnly
                  />
                </div> */}
							</div>
							<div className='signate_img'>
								{acctMngSignImageData != null && acctMngSignImageData !== '' ? (
									<img
										src={
											acctMngSignImageData != null
												? 'data:' +
												  imageType +
												  ';base64,' +
												  encode(acctMngSignImageData)
												: ''
										}
										alt={'Account Manager Sign'}
									/>
								) : (
									''
								)}
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Circle Office of the Account Manager:
								</div>
								<div className='fleft width60'>
									<input
										className='form_col width60'
										type='text'
										name
										value={cafDataupdated[314306].value}
										readOnly
									/>
								</div>
							</div>
							<div className='auth margin0 over_hid pad_5t'>
								<div className='fleft pad_r50 width25'>
									Signature of the Customer:
								</div>
								<div className='fleft width60'>
									<input
										className='form_col width60'
										type='text'
										name
										value={cafDataupdated[314307].value}
										readOnly
									/>
								</div>
							</div>
							<p>Seal:</p>
							<div style={{ width: '90rem' }}>
								<div className='signate float_img' style={{ bottom: '20px' }}>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}

									{imageData != null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
							<div className='fleft pad_r50 width25'>Date:</div>
							<div className='fleft width60'>
								<input
									className='form_col width60'
									style={{ fontSize: '15px' }}
									type='text'
									name
									value={cafDataupdated[314309].value}
									readOnly
								/>
							</div>
							{/* <p>Date:</p> */}
						</div>
					</div>
				</page>
				<TermsConditionNew
					firstCaps={''}
					Signcss={''}
					secondBold={''}
					imageData={''}
					imageType={''}
					isFlv={true}
					CustomComponent={CustomComponent}
				/>

				<page>
					<div className='section2 font8'>
						<div style={{ width: '90rem' }}>
							<div className='signate float_img' style={{ bottom: '20px' }}>
								{firstCaps !== '' ? (
									<div style={{ padding: '10px' }} className={Signcss}>
										{firstCaps + '  .  '}
										<span className='under_line'>{secondBold}</span>
									</div>
								) : (
									''
								)}

								{imageData !== null && imageData !== '' ? (
									<div style={{ padding: '10px' }} className='signate_img'>
										<img
											src={
												imageData != null
													? 'data:' + imageType + ';base64,' + encode(imageData)
													: ''
											}
											alt={'Company Seal'}
										/>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</page>
			</div>
		)
	} else {
		cafContentView = cafData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{cafContentView}</div>
}

export default GIPTCafForm
